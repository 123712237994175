<template>
    <campaign-banner
        :banner-name="bannerName"
        :class="classes"
        :is-resizable="isResizable"
        :style="internalContainerStyle"
        :theme="theme"
        @resized="onResized"
    >
        <div v-if="showSocialPreviewOptions" :class="socialPreviewWrapperClass">
            <Select v-model="adType" class="campaign-edit__header-action-select">
                <Option value="news-feed">Facebook News Feed</Option>
                <Option value="news-feed|desktop|500">
                    Facebook News Feed
                    <span>(desktop)</span>
                </Option>
                <Option value="news-feed|video">
                    Facebook Video Feed
                    <span>(videos - mobile)</span>
                </Option>
                <Option value="news-feed|tab">
                    Facebook Video Feed
                    <span>(video tab - mobile)</span>
                </Option>
                <Option value="facebook-reel">
                    Facebook Video Feed
                    <span>(fullscreen - mobile)</span>
                </Option>
                <Option value="facebook-story">Facebook Story</Option>
                <Option value="facebook-reel">Facebook Reel</Option>
                <Option value="facebook-in-stream|video">
                    Facebook In-Stream Video
                    <span>(mobile)</span>
                </Option>
                <Option value="facebook-in-stream|video|500">
                    Facebook In-Stream Video
                    <span>(desktop)</span>
                </Option>
                <Option value="facebook-in-stream|banner">
                    Facebook In-Stream Banner
                    <span>(mobile)</span>
                </Option>
                <Option value="facebook-in-stream|banner|500">
                    Facebook In-Stream Banner
                    <span>(desktop)</span>
                </Option>
                <Option value="facebook-in-stream-full|video">
                    Facebook In-Stream Video
                    <span>(mobile fullscreen)</span>
                </Option>
                <Option value="facebook-in-stream-full|banner">
                    Facebook In-Stream Banner
                    <span>(mobile fullscreen)</span>
                </Option>
                <Option value="instagram-feed">Instagram Feed</Option>
                <Option value="instagram-reel">Instagram Reel</Option>
                <Option value="facebook-story|instagram">Instagram Story</Option>
            </Select>
        </div>
        <scaling-header
            v-if="showScalingControls && !isResizable && !showSocialPreviewOptions"
            :scaling-is-enabled="scalingIsEnabled"
            :internal-scaling-factor="internalScalingFactor"
            :is-resizable="isResizable"
            :theme="theme"
            @scaling-is-enabled="toggleScalingIsEnabled"
        />

        <resize-header
            v-if="isResizable && showResizeHeader && !showSocialPreviewOptions"
            :scaling-is-enabled="scalingIsEnabled"
            :internal-scaling-factor="internalScalingFactor"
            :is-resizable="isResizable"
            :resize-dimensions="resizeDimensions"
            :theme="theme"
            @disableResize="toggleResizeMode"
        />

        <slot name="headers" :slot-props="slotProps"></slot>

        <slot name="beforeBannerContainer"></slot>

        <div class="campaign-banner__banner-container" :style="bannerContainerStyle">
            <slot name="bannerContainer" :slot-props="slotProps"></slot>
        </div>

        <template slot="footer">
            <slot name="footer" :slot-props="slotProps" :slot-toggle-resize-mode="toggleResizeMode">
                <campaign-banner-footer>
                    <template slot="name">
                        <slot name="footerName" :slot-props="slotProps">
                            <Poptip
                                v-model="infoVisible"
                                placement="bottom-start"
                                class="campaign-edit__poptip hide-title"
                                :popper-class="popperClasses"
                                trigger="hover"
                                :transfer="true"
                            >
                                <span class="banner-name">
                                    {{ bannerName }}
                                </span>
                                <div slot="content" class="campaign-edit__poptip-templateName">
                                    <p>{{ bannerName }}</p>
                                </div>
                            </Poptip>
                        </slot>
                    </template>
                    <template #controls>
                        <slot name="footerControls" :slot-props="slotProps"></slot>
                    </template>
                    <template #options>
                        <slot
                            name="footerOptions"
                            :slot-props="slotProps"
                            :slot-toggle-resize-mode="toggleResizeMode"
                        ></slot>
                    </template>
                </campaign-banner-footer>
            </slot>
        </template>
    </campaign-banner>
</template>
<script>
import CampaignBanner from "@/components/Campaign/CampaignBanner";
import ScalingHeader from "@/components/Campaign/ScalingHeader";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import scalingBannerMixin from "@/mixins/scalingBannerMixin";
import ResizeHeader from "@/components/Campaign/ResizeHeader";
import CampaignBannerFooter from "@/components/Campaign/CampaignBannerFooter";

export default {
    name: "ResizableCampaignBanner",
    components: {
        CampaignBannerFooter,
        ResizeHeader,
        CampaignBanner,
        ScalingHeader
    },
    mixins: [editableMethodsMixin, scalingBannerMixin],

    props: {
        bannerName: {
            type: String
        },

        className: {
            type: String,
            default: "resizable-campaign-banner"
        },

        masterTemplate: {
            type: Object,
            required: true,
            default() {
                return {
                    width: 0,
                    height: 0
                };
            }
        },

        sandboxMode: {
            type: Boolean,
            default: false
        },

        theme: {
            type: String,
            default: "light"
        },

        showSocialPreviewOptions: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            infoVisible: false,
            resizeDimensions: "",
            adType: ""
        };
    },

    computed: {
        canManageMasterTemplates() {
            return this.$auth.userCan(this.$auth.Actions.CanManageMasterTemplates, { clientId: this.clientId });
        },

        classes() {
            const classes = [this.className];
            if (this.showSocialPreviewOptions) {
                classes.push("campaign-banner--meta-preview");
            }
            if (!this.canManageMasterTemplates && !this.sandboxMode) {
                classes.push("hide-footer-options");
            }

            if (this.isResizable) {
                classes.push(`${this.className}--resizable`);
            }

            return classes;
        },

        popperClasses() {
            const classes = ["campaign-edit__poptip", "campaign-edit__poptip--transferred"];

            if (this.theme === "dark") {
                classes.push("theme-campaign-dark");
            }
            return classes.join(" ");
        },

        slotProps() {
            return {
                internalScalingFactor: this.internalScalingFactor,
                scalingIsEnabled: this.scalingIsEnabled,
                isResizable: this.isResizable
            };
        },

        socialPreviewWrapperClass() {
            if (!this.showScalingControls || !this.showResizeHeader) {
                return "campaign-banner__social--no-resize";
            }

            return "campaign-banner__social";
        }
    },

    watch: {
        adType(val) {
            const types = val.split("|");
            this.$emit("changeAdType", {
                adType: types[0],
                adSubType: types[1] ? types[1] : "",
                adSize: types[2] ? types[2] : 300
            });
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.resizable-campaign-banner {
    .campaign-banner__social {
        z-index: 22;
        top: 0;
        margin: 10px;
        width: 100%;
        .campaign-edit__header-action-select {
            max-width: auto;
            margin-left: 10px;
        }
    }

    .campaign-banner__social--no-resize {
        background: #fff;
        padding: 10px;
        position: relative;
        width: 100%;
    }

    &.campaign-banner--meta-preview {
        .banner-preview {
            display: flex;
        }
    }
}
.campaign-banner.hide-footer-options .campaign-banner-footer__options {
    display: none;
}

.campaign-banner__banner-container {
    flex: 1 1;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    &-checkbox {
        width: 100%;
        height: 100%;
    }

    .ivu-checkbox {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    .ivu-checkbox-inner {
        height: 20px;
        margin: $spacing-small 0 0 $spacing-small;
        width: 20px;

        &:after {
            height: 14px;
            left: 8px;
        }
    }

    .ivu-checkbox-wrapper {
        margin: 0;
    }
}
.campaign-edit__header-action-select {
    span {
        display: block;
        font-size: 12px;
    }
}
</style>
