<template>
    <form @submit.prevent="submit">
        <hox-input
            ref="campaignName"
            data-testid="create-campaign__name"
            :value="internalFormData.name"
            is-required
            :show-error="showValidationErrors && validationErrors.name !== undefined"
            @input="onInternalFormNameChange"
            @enter="onInternalFormNameChangeSave"
        >
            <template #label>Campaign name</template>
            <template #error>
                <p v-for="error in validationErrors.name" :key="error">
                    {{ error }}
                </p>
            </template>
        </hox-input>
        <hox-input
            data-testid="create-campaign__jira-ticket"
            :value="internalFormData.jiraTicketUrl"
            @input="onInternalFormJiraChange"
        >
            <template #label>Jira link</template>
            <template #error>
                <p v-for="error in validationErrors.jiraTicketUrl" :key="error">
                    {{ error }}
                </p>
            </template>
        </hox-input>
        <resource-group-input
            v-if="isAdmin"
            :value="internalFormData.resourceGroupIds"
            @input="onChangeFormResourceGroupIds"
        />
        <sections-select v-model="internalFormData.sectionId" :sections="sections" />
        <image-upload
            v-if="uploadConfig"
            data-testid="edit-campaign__image"
            :value="internalFormData.mastheadImageUrl"
            :config="uploadConfig"
            @imageUploaded="setCampaignImage"
        >
            <template #label>Campaign image</template>
        </image-upload>

        <template>
            <span>Tone of Voice</span>
            <Input
                ref="toneOfVoice"
                type="textarea"
                :value="internalFormData.toneOfVoice"
                @input="onInternalFormToneOfVoice"
            ></Input>
        </template>

        <hox-input
            data-testid="create-campaign__jira-ticket"
            :value="internalFormData.defaultTimelineLength"
            placeholder="30"
            type="number"
            @input="onInternalFormTimelineDuration"
        >
            <template #label>Default timeline duration (in seconds)</template>
            <template #error>
                <p v-for="error in validationErrors.defaultTimelineLength" :key="error">
                    {{ error }}
                </p>
            </template>
        </hox-input>
    </form>
</template>

<script>
import Vue from "vue";
import ImageUpload from "@/components/ImageUpload";
import SectionsSelect from "@/components/SectionsSelect";
import { AuthGetters } from "@/store/modules/auth";
import ResourceGroupInput from "@/components/CreativeIntelligence/ResourceGroupInput";
// eslint-disable-next-line no-unused-vars
const urlRegex = /http(s)?:\/\/[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
const nameWhitelistRegex = /[^a-zA-Z0-9_\s\-<>]+/;

export default {
    name: "CampaignForm",

    components: {
        ImageUpload,
        SectionsSelect,
        ResourceGroupInput
    },

    props: {
        externalErrors: {
            required: true,
            type: Object
        },

        initialFormData: {
            type: Object
        },

        sections: {
            required: true,
            type: Array,
            validator(value) {
                return value.every(item => item._id !== undefined && item.name !== undefined);
            }
        },

        showValidationErrors: {
            type: Boolean
        },

        uploadConfig: {
            type: Object
        }
    },

    data() {
        return {
            internalFormData: {
                ...this.initialFormData,
                sectionId:
                    this.initialFormData && this.initialFormData.sectionId ? this.initialFormData.sectionId : "none"
            },
            touched: false
        };
    },

    computed: {
        hasValidationErrors() {
            return Object.keys(this.validationErrors).length > 0;
        },

        isAdmin() {
            return this.$store.getters[AuthGetters.isSuperAdmin] || this.$store.state.auth.me.role.name === "Admin";
        },

        validationErrors() {
            const errors = {};

            const validateName = () => {
                if (this.externalErrors.name && !this.touched) {
                    errors.name = this.externalErrors.name;
                } else if (!this.internalFormData.name || this.internalFormData.name.trim() === "") {
                    errors.name = ["A campaign requires a name"];
                } else if (this.internalFormData.name && nameWhitelistRegex.test(this.internalFormData.name)) {
                    errors.name = [
                        "A campaign name can only contain numbers, letters, spaces and following characters: -, _, <, >"
                    ];
                }
            };

            const validateJiraTicket = () => {
                // eslint-disable-next-line no-useless-escape

                if (this.externalErrors.jiraTicketUrl && !this.touched) {
                    errors.jiraTicketUrl = this.externalErrors.jiraTicketUrl;
                } else if (
                    this.internalFormData.jiraTicketUrl &&
                    !this.internalFormData.jiraTicketUrl.match(urlRegex)
                ) {
                    errors.jiraTicketUrl = ["The url doesn't seem to be valid. Example: https://example.com"];
                }
            };

            const validateDefaultTimelineLength = () => {
                const defaultTimelineNumber = parseFloat(this.internalFormData.defaultTimelineLength);
                if (this.externalErrors.defaultTimelineLength && !this.touched) {
                    errors.defaultTimelineLength = this.externalErrors.defaultTimelineLength;
                } else if (defaultTimelineNumber < 0) {
                    errors.defaultTimelineLength = ["The value needs to be a valid duration"];
                }
            };

            validateName();
            validateJiraTicket();
            validateDefaultTimelineLength();

            return errors;
        }
    },

    watch: {
        externalErrors() {
            this.touched = false;
        },

        hasValidationErrors: {
            handler() {
                this.$emit("hasValidationErrorsUpdate", this.hasValidationErrors);
            },
            immediate: true
        },

        internalFormData: {
            deep: true,
            handler() {
                this.$emit("formDataUpdate", { ...this.internalFormData });
            },
            immediate: true
        }
    },

    async mounted() {
        await this.$nextTick();
        this.$refs.campaignName.focus();
    },

    methods: {
        onInternalFormNameChange(value) {
            this.touched = true;
            Vue.set(this.internalFormData, "name", value);
        },
        onInternalFormNameChangeSave(value) {
            this.onInternalFormNameChange(value);
            this.submit();
        },

        onInternalFormJiraChange(value) {
            this.touched = true;
            Vue.set(this.internalFormData, "jiraTicketUrl", value);
        },

        onInternalFormTimelineDuration(value) {
            this.touched = true;
            Vue.set(this.internalFormData, "defaultTimelineLength", value);
        },

        onChangeFormResourceGroupIds(value) {
            this.touched = true;
            Vue.set(this.internalFormData, "resourceGroupIds", value);
        },

        onInternalFormToneOfVoice(value) {
            this.touched = true;
            Vue.set(this.internalFormData, "toneOfVoice", value);
        },

        setCampaignImage(imagePath) {
            this.$set(this.internalFormData, "content", imagePath);
        },

        submit() {
            this.$emit("submit", { ...this.internalFormData });
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
