<template>
    <div class="livebanner-wrapper" :class="{ 'livebanner-wrapper--resizable': isResizable }">
        <banner-preview
            :key="refreshKey"
            :banner="previewBannerAdapter"
            :preview-values="livePreviewValues"
            :editor-values="liveEditorValues"
            :content-clickable="true"
            :debug="true"
            :ad-type="masterTemplate.adType"
            :is-resizable="isResizable"
            :scaling-factor="scalingFactor"
            @BannerLoaded="onBannerLoaded"
        />
    </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import * as Types from "shared-utils/types";
// eslint-disable-next-line import/no-extraneous-dependencies
import nl2br from "shared-utils/nl2br";
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";

import BannerPreview from "@/components/Previews/BannerPreview";
import { CampaignGetters } from "@/store/modules/campaign";
import { debounce, getObjectValue, kebabCase } from "@/utils";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import { EditableMediaTypes, EditableTranslateMethod, EditableType } from "@/enums/editables";
import { MediaLibraryAssetEvent } from "@/components/MediaLibrary";
import bus from "@/bus";
import { BannerEvents } from "@/enums/banners";
import { cleanAWSTokens } from "@/utils/urls";

export default {
    name: "LiveBannerPreview",
    components: { BannerPreview },
    mixins: [editableMethodsMixin],
    props: {
        masterTemplate: {
            type: Object,
            required: true
        },

        previewValues: {
            type: String,
            required: true
        },

        scalingFactor: {
            type: [Number, String]
        },

        isResizable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            appliedValues: {
                /* { [language: string] : {[appliedValueId]: editableId(value)} } */
            },
            // A debounced function needs to be unique for every instance of the component
            // and as such should be defined as a data property not a method as methods are shared
            reloadPsdTempate: debounce(function handler() {
                this.livePreviewValues = this.previewValues;
                this.refreshKey += 1;
            }, 500),
            stagedChanges: {},
            removedAsset: null,
            refreshKey: 0,
            livePreviewValues: "",
            liveUpdates: {},
            addedFonts: [],
            waitForElementCounter: 0,
            liveEditorValues: {}
        };
    },

    computed: {
        editablesWithLocalOverwrites() {
            const localChanges = this.$store.state.editor.localEditableOverwrites.reduce((acc, editableOverwrite) => {
                const groups = this.getAffectingGroups(editableOverwrite.editable, this.masterTemplate._id);
                const relevantGVIds = groups.reduce((accumulator, curr) => {
                    return accumulator.concat(
                        this.$store.state.campaign.normalized.editableGroups[curr].editableGroupValues
                    );
                }, []);

                const relevantOverwrites = editableOverwrite.overwrites.filter(overwrite =>
                    overwrite.editableGroupValueIds.some(egvId => relevantGVIds.includes(egvId))
                );

                if (relevantOverwrites.length) {
                    acc.push(editableOverwrite.editable._id);
                }

                return acc;
            }, []);
            return this.templateEditables.filter(editable => localChanges.includes(editable._id));
        },

        groupEditables() {
            return this.$store.getters[CampaignGetters.groupEditables];
        },

        iframeId() {
            return `iframe-${this.masterTemplate._id}`;
        },

        normalizedTemplateEditables() {
            return this.templateEditables.reduce((acc, cur) => Object.assign(acc, { [cur._id]: cur }), {});
        },

        previewBannerAdapter() {
            return {
                ...this.masterTemplate,
                id: this.iframeId
            };
        },

        selectedLanguage() {
            return this.$store.state.editor.selectedLanguage;
        },

        templateEditables() {
            return this.$store.getters[CampaignGetters.templateEditables](this.masterTemplate._id);
        },

        templateEditablesIds() {
            return this.templateEditables.map(editable => editable._id);
        }
    },

    watch: {
        masterTemplate(value, oldValue) {
            if (value.preview !== oldValue.preview) {
                this.livePreviewValues = this.previewValues;
            }

            if (value.modified !== oldValue.modified) {
                this.refreshKey += 1;
            }
        },

        templateEditables() {
            this.updateDomTemplateEditables();
        }
    },

    created() {
        this.initAppliedValues();
        this.livePreviewValues = this.previewValues;

        this.unsubscribeOverwritesWatcher = this.$store.watch(
            state => state.editor.localEditableOverwrites,
            this.watchOverwrites.bind(this)
        );
        this.unsubscribeRemovedOverwritesWatcher = this.$store.watch(
            state => state.editor.removedOverwriteIds,
            this.watchRemovedOverwrites.bind(this)
        );
        this.unsubscribeSavedOverwritesWatcher = this.$store.watch(
            state => state.campaign.normalized.overwrites,
            this.watchSavedOverwrites.bind(this)
        );
        this.unsubscribeLanguageWatcher = this.$store.watch(
            state => state.editor.selectedLanguage,
            this.watchLanguage.bind(this)
        );
        this.unsubscribeGroupValuesWatcher = this.$store.watch(
            state => state.editor.selectedGroupValueIds,
            this.watchGroupValues.bind(this)
        );

        bus.$on(MediaLibraryAssetEvent.AssetRemoved, this.onAssetRemoved);
        // This code is meant to be used in sandbox mode to reapply values on hot reload
        // It can be called from within the banner template using postMessage after the banner is initiated
        //  and manifest values are set
        window.addEventListener("message", this.onBannerContentLoaded, false);
    },

    beforeDestroy() {
        this.unsubscribeGroupValuesWatcher();
        this.unsubscribeLanguageWatcher();
        this.unsubscribeOverwritesWatcher();
        this.unsubscribeRemovedOverwritesWatcher();
        this.unsubscribeSavedOverwritesWatcher();
        window.removeEventListener("message", this.onBannerContentLoaded, false);
    },

    methods: {
        getAppliedValuesKey(editable) {
            const groupsAffectingEditable =
                this.normalizedTemplateEditables[editable._id].defaultValues[0].editableGroups;
            return `${editable._id}-${groupsAffectingEditable.sort().join()}`;
        },

        getLiveEditableValue(editable, groupsAffectingEditable, masterTemplate) {
            // The preview function gets an image thumbnail for folder types and converts nl2br for textareas
            let value = this.getEditablePreviewValue(editable, groupsAffectingEditable, masterTemplate);

            if (editable.type === EditableType.Folder) {
                value += "index.html";
            }

            return value;
        },

        getLiveEditableSettings(editable, groupsAffectingEditable, masterTemplateId) {
            // This function internally handles the merging of overwrite levels
            return this.getEditableSettings(editable, groupsAffectingEditable, masterTemplateId);
        },

        initAppliedValues() {
            this.templateEditables.forEach(editable => {
                const groupsAffectingEditable = editable.defaultValues[0].editableGroups;
                const value = this.getLiveEditableValue(editable, groupsAffectingEditable, this.masterTemplate);
                const settings = this.getLiveEditableSettings(
                    editable,
                    groupsAffectingEditable,
                    this.masterTemplate._id
                );

                this.appliedValues[this.getAppliedValuesKey(editable)] = {
                    editable,
                    value,
                    settings
                };
            });
        },

        onAssetRemoved(asset) {
            this.removedAsset = asset;
        },

        onBannerContentLoaded(evt) {
            if (evt.data === "contentLoaded") {
                this.reapplyValues();
            }
        },

        onBannerLoaded(payload) {
            const stagedKeys = Object.keys(this.stagedChanges);
            if (stagedKeys.length) {
                stagedKeys.forEach(stagedEditableId =>
                    this.updateDom(
                        this.stagedChanges[stagedEditableId].editable,
                        this.stagedChanges[stagedEditableId].value,
                        this.stagedChanges[stagedEditableId].settings
                    )
                );
                this.stagedChanges = {};
            }

            this.doReapplyValues();
            // force banner to reapply the live styles on first load ( scenario where we go from editor to library and back to the editor and we have selected group values)
            if (
                this.masterTemplate.adType === TemplateType.HTML5 &&
                this.$store.state.editor.selectedGroupValueIds.length
            ) {
                this.templateEditables.forEach(editable => {
                    const groupsAffectingEditable = editable.defaultValues[0].editableGroups;
                    const settings = this.getLiveEditableSettings(
                        editable,
                        groupsAffectingEditable,
                        this.masterTemplate._id
                    );

                    this.updateHtmlStyles(this.normalizedTemplateEditables[editable._id], settings);
                });
            }

            this.$emit(BannerEvents.BannerLoaded, payload);
        },

        reapplyValues() {
            this.waitForElementCounter = 0;
            const appliedValuesKeys = Object.keys(this.liveUpdates);
            const editableIdThatShouldExist = appliedValuesKeys.filter(avk => {
                return this.appliedValues[avk].domElementExists;
            });

            if (editableIdThatShouldExist.length) {
                this.waitForElement(
                    this.iframeId,
                    this.liveUpdates[editableIdThatShouldExist[0]].editable.name,
                    this.doReapplyValues
                );
            } else if (appliedValuesKeys.length) {
                this.doReapplyValues();
            }
        },

        updateDomTemplateEditables() {
            this.templateEditables.forEach(editable => {
                const groupsAffectingEditable = editable.defaultValues[0].editableGroups;
                this.updateEditableDomValue(this.normalizedTemplateEditables[editable._id], groupsAffectingEditable);
            });
        },

        waitForElement(iframeId, editableName, done) {
            this.waitForElementCounter += 1;
            const iframe = this.$el.querySelector(`#${iframeId}`);
            const selector = `#${editableName}`;

            if (iframe.contentDocument.querySelectorAll(selector).length) {
                done();
            } else if (this.waitForElementCounter < 10) {
                setTimeout(() => {
                    this.waitForElement(iframeId, editableName, done);
                }, 1000);
            }
        },

        doReapplyValues() {
            const appliedValuesKeys = Object.keys(this.liveUpdates);
            if (appliedValuesKeys.length) {
                appliedValuesKeys.forEach(stagedEditableId => {
                    this.updateDom(
                        this.liveUpdates[stagedEditableId].editable,
                        this.liveUpdates[stagedEditableId].value,
                        this.liveUpdates[stagedEditableId].settings
                    );
                });
            }
        },

        /* eslint-disable no-param-reassign */
        setDivTextValue: (element, value, editable) => {
            if (editable.type === EditableType.Image) {
                const elementStyles = window.getComputedStyle(element);

                if (elementStyles.backgroundImage !== "none") {
                    element.style.backgroundImage = `url(${value})`;
                    return;
                }
            }

            if (editable.type === EditableType.BackgroundImage) {
                element.style.backgroundImage = `url(${value})`;
                return;
            }

            if (editable.type === EditableType.Textarea) {
                element.innerHTML = nl2br(value);
                return;
            }

            // a hack to make image wrapped with a div to update correctly upon asset selection change
            if (getObjectValue(element, "firstChild.nodeName") === "IMG") {
                element.children[0].src = value;
            } else {
                element.innerHTML = value;
            }
        },

        setVisibility(style, isVisible = true) {
            const visibility = isVisible ? "visible" : "hidden";
            Object.assign(style, { visibility });
        },

        setFontColor(style, fontColor) {
            if (fontColor !== undefined) {
                Object.assign(style, { color: fontColor });
            }
        },

        setFontSize(style, fontSize) {
            if (fontSize !== undefined) {
                Object.assign(style, { fontSize: `${fontSize}px` });
            }
        },

        setFontWeight(style, fontWeight) {
            if (fontWeight !== undefined) {
                Object.assign(style, { fontWeight });
            }
        },

        setFontStyle(style, fontStyle) {
            if (fontStyle !== undefined) {
                Object.assign(style, { fontStyle });
            }
        },

        setLetterSpacing(style, letterSpacing) {
            if (letterSpacing !== undefined) {
                Object.assign(style, { letterSpacing: `${letterSpacing}px` });
            }
        },

        setLineHeight(style, lineHeight) {
            if (lineHeight !== undefined) {
                Object.assign(style, { lineHeight: `${lineHeight}px` });
            }
        },

        setHeight(style, height) {
            if (height !== undefined) {
                Object.assign(style, { height: `${height}px` });
            }
        },

        setWidth(style, width) {
            if (width !== undefined) {
                Object.assign(style, { width: `${width}px` });
            }
        },

        setScale(style, scale) {
            if (scale !== undefined) {
                const currentTransforms = style.transform;
                const scaleCss = `scale(${scale / 100})`;

                if (!currentTransforms) {
                    Object.assign(style, { transform: `${scaleCss}` });
                    return;
                }

                let transform = currentTransforms.replace(/scale\([^)]*\)(;|)?/, "");
                transform = transform ? `${transform} ${scaleCss}` : `${scaleCss}`;

                Object.assign(style, { transform });
            }
        },

        resetTransformParam(el) {
            const currentTransforms = el.style.transform;
            const transformWithoutTranslate = currentTransforms
                ? currentTransforms.replace(/translate\([^)]*\)(;|)?/, "")
                : currentTransforms;
            const transform = transformWithoutTranslate
                ? transformWithoutTranslate.replace(/scale\([^)]*\)(;|)?/, "")
                : transformWithoutTranslate;
            if (transform) {
                el.style.setProperty("transform", transform);
            } else {
                el.style.removeProperty("transform");
            }
        },

        // eslint-disable-next-line complexity
        setTranslation(style, settings, editableType) {
            const { translationMethod, top, left } = settings;
            const position = {};
            const validCssNumericValue = val => val !== undefined && val !== null;

            let method = translationMethod === undefined ? EditableTranslateMethod.Relative : translationMethod;

            if (editableType === EditableType.BackgroundImage) {
                method = EditableTranslateMethod.BackgroundPosition;
            }

            if (method === EditableTranslateMethod.Relative) {
                if (validCssNumericValue(top)) {
                    position.top = `${top}px`;
                }
                if (validCssNumericValue(left)) {
                    position.left = `${left}px`;
                }
                if (validCssNumericValue(top) || validCssNumericValue(left)) {
                    position.position = "relative";
                }
            } else if (method === EditableTranslateMethod.Transform) {
                const y = validCssNumericValue(top) ? top : 0;
                const x = validCssNumericValue(left) ? left : 0;

                const currentTransforms = style.transform;
                const translateCss = `translate(${x}px, ${y}px)`;

                if (!currentTransforms) {
                    position.transform = translateCss;
                } else {
                    let transform = currentTransforms.replace(/translate\([^)]*\)(;|)?/, "");
                    transform = transform ? `${transform} ${translateCss}` : `${translateCss}`;

                    position.transform = transform;
                }
            } else if (method === EditableTranslateMethod.Margin) {
                if (validCssNumericValue(top)) {
                    position.marginTop = `${top}px`;
                }
                if (validCssNumericValue(left)) {
                    position.marginLeft = `${left}px`;
                }
            }
            if (method === EditableTranslateMethod.BackgroundPosition) {
                if (validCssNumericValue(top)) {
                    position.backgroundPositionY = `${top}px`;
                }
                if (validCssNumericValue(left)) {
                    position.backgroundPositionX = `${left}px`;
                }
            }

            Object.assign(style, position);
        },

        setFontFamily(style, fontFamily, iframe) {
            if (fontFamily !== undefined) {
                if (!this.addedFonts.includes(fontFamily.name)) {
                    this.addedFonts.push(fontFamily.name);

                    const head = iframe.head || iframe.getElementsByTagName("head")[0];
                    const fontFaceStyle = iframe.createElement("style");
                    const cssText = `@font-face {
                        font-family: '${fontFamily.name}';
                        src: url('${fontFamily.url}');
                    }`;

                    head.appendChild(fontFaceStyle);
                    fontFaceStyle.type = "text/css";
                    fontFaceStyle.appendChild(iframe.createTextNode(cssText));
                }

                Object.assign(style, { fontFamily: `"${fontFamily.name}"` });
            }
        },

        shouldUpdateSettings(settings, editable) {
            const appliedValueKey = this.getAppliedValuesKey(editable);

            return (
                settings &&
                this.appliedValues[appliedValueKey] &&
                this.appliedValues[appliedValueKey].settings &&
                JSON.stringify(this.appliedValues[appliedValueKey].settings) !== JSON.stringify(settings)
            );
        },

        triggerBannerUpdate(update) {
            this.callBannerMethod("hoxton.setState", update);
            this.callBannerMethod("Creative.updateContent", update);
        },

        /**
         * @param {string} method the name of the method to call on the iframe contentWindow. Can specify dot notation e.g. hoxton.setState
         * @param {{name: string, type:string, value: any}} update the update to pass into the method
         */
        callBannerMethod(method, update) {
            const iframe = this.$el.querySelector(`#${this.iframeId}`);
            const callback = getObjectValue(iframe, `contentWindow.${method}`);
            if (callback && typeof callback === "function") {
                callback(update);
            }
        },

        updateDomSettings(node, settings, iframe, editable) {
            const appliedStyles = node.dataset.appliedSettings;
            const appliedStylesList = appliedStyles ? appliedStyles.split(",") : [];

            const style = {};

            this.setVisibility(style, settings.visible);
            this.setFontColor(style, settings.fontColor);
            this.setFontFamily(style, settings.fontFamily, iframe);
            this.setFontSize(style, settings.fontSize);
            this.setFontWeight(style, settings.fontWeight);
            this.setFontStyle(style, settings.fontStyle);
            this.setLetterSpacing(style, settings.letterSpacing);
            this.setLineHeight(style, settings.lineHeight);
            this.setHeight(style, settings.height);
            this.setWidth(style, settings.width);
            this.resetTransformParam(node);
            this.setTranslation(style, settings, editable.type);
            this.setScale(style, settings.scale);

            const propsToUpdate = Object.keys(style);

            if (appliedStyles) {
                appliedStylesList.forEach(styleProp => {
                    if (!propsToUpdate.includes(styleProp)) {
                        node.style.removeProperty(kebabCase(styleProp));
                    }
                });
            }

            Object.assign(node.style, style);
            node.dataset.appliedSettings = propsToUpdate;
        },

        updatePsd(editable, value, settings) {
            if (editable.layers) {
                const values = JSON.parse(this.previewValues);
                const layer = editable.layers.find(
                    ({ masterTemplateId }) => masterTemplateId === this.masterTemplate._id
                );
                const layerInTemplate = values.computedOverwrites.find(
                    overwrite => layer && overwrite.layer && overwrite.layer.id === layer.value.id
                );
                if (layerInTemplate) {
                    this.reloadPsdTempate();
                }
            }

            this.appliedValues[this.getAppliedValuesKey(editable)] = { editable, value, settings };
        },

        /**
         * Update After Effects editable
         * @param {Types.Editable} editable
         * @param {string} value
         * @param {Object} settings
         */
        updateAE(editable, value, settings) {
            if (editable.layers?.find(({ masterTemplateId }) => masterTemplateId === this.masterTemplate._id)) {
                this.liveEditorValues = {
                    ...(this?.liveEditorValues ? this.liveEditorValues : {}),
                    [editable.name]: value
                };
            }

            this.appliedValues[this.getAppliedValuesKey(editable)] = { editable, value, settings };
        },

        updateHtml(editable, value, settings) {
            let domElementExists = false;

            // Update dom for html banners
            const iframe = this.$el.querySelector(`#${this.iframeId}`);
            const selector = `#${editable.name}`;

            if (iframe.contentDocument === null) {
                this.stagedChanges[editable._id] = {
                    editable,
                    value,
                    settings
                };

                return;
            }

            const templateId = this.iframeId.replace("iframe-", "");
            const defaultValue = editable.defaultValues.find(dv => dv.masterTemplateId === templateId);
            // Make sure the iframes folder editables are loaded from the same domain as the client when not using the defaultValue
            // This ensures the iframes are loaded from the correct server in sandbox mode
            const iframeFolderValue = elem => {
                if (defaultValue && (defaultValue.value === value || `${defaultValue.value}index.html` === value)) {
                    elem.src = value;
                    return;
                }

                elem.src = `${window.location.origin}${value}`;
            };
            /* eslint-disable-next-line complexity */
            iframe.contentDocument.querySelectorAll(selector).forEach(e => {
                switch (e.nodeName) {
                    case "IFRAME":
                        if (editable.type === EditableType.Folder) {
                            iframeFolderValue(e);
                            break;
                        }
                        e.src = value;
                        break;
                    case "IMG":
                        e.src = value;
                        break;
                    case "SOURCE":
                    case "VIDEO":
                        e.src = value;
                        try {
                            iframe.contentDocument.querySelector("video").load();
                        } catch (err) {
                            // eslint-disable-next-line no-console
                            console.error("Video load error", err);
                        }
                        break;
                    case "DIV":
                        this.setDivTextValue(e, value, editable);
                        break;
                    default:
                        if (editable.type === EditableType.Textarea) {
                            e.innerHTML = nl2br(value);
                        } else {
                            e.innerHTML = value;
                        }
                }

                if (this.shouldUpdateSettings(settings, editable)) {
                    this.updateDomSettings(e, settings, iframe.contentDocument, editable);
                }

                domElementExists = true;
            });

            const update = {
                name: editable.name,
                type: editable.type,
                value
            };
            this.triggerBannerUpdate(update);

            // Make sure we do not have undefined for the domElementExists check
            const appliedValueKey = this.getAppliedValuesKey(editable);
            const appliedValue = this.appliedValues[appliedValueKey] || {};

            this.appliedValues[appliedValueKey] = {
                editable,
                value,
                settings,
                domElementExists: appliedValue.domElementExists || domElementExists
            };
        },

        updateHtmlStyles(editable, settings) {
            const iframe = this.$el.querySelector(`#${this.iframeId}`);
            const selector = `#${editable.name}`;

            /* eslint-disable-next-line complexity */
            iframe.contentDocument.querySelectorAll(selector).forEach(e => {
                const settingsProps = Object.keys(settings);
                if (settingsProps.length) {
                    this.updateDomSettings(e, settings, iframe.contentDocument, editable);
                }
            });
        },

        updateDom(editable, value, settings) {
            // PSD banners are just images so need a refresh
            if (this.masterTemplate.adType === TemplateType.PSD) {
                this.updatePsd(editable, value, settings);
            } else if (this.masterTemplate.adType === TemplateType.AE) {
                this.updateAE(editable, value, settings);
            } else {
                this.updateHtml(editable, value, settings);
            }
        },

        updateLiveDom(editable, value, settings) {
            this.liveUpdates[this.getAppliedValuesKey(editable)] = {
                editable,
                value,
                settings
            };
            this.updateDom(editable, value, settings);
        },

        updateEditableDomValue(editable, groupsAffectingEditable) {
            const editableValue = this.getLiveEditableValue(editable, groupsAffectingEditable, this.masterTemplate);
            const settings = this.getLiveEditableSettings(editable, groupsAffectingEditable, this.masterTemplate._id);
            const appliedValuesKey = this.getAppliedValuesKey(editable);

            const shouldUpdateDom = () => {
                // Not yet applied
                if (!(this.getAppliedValuesKey(editable) in this.appliedValues)) {
                    return true;
                }
                // Settings change
                if (this.shouldUpdateSettings(settings, editable)) {
                    return true;
                }
                // Media type
                if (EditableMediaTypes.includes(editable.type)) {
                    const cleanOldVal = cleanAWSTokens(this.appliedValues[appliedValuesKey].value);
                    const cleanNewVal = cleanAWSTokens(editableValue);
                    return cleanNewVal !== cleanOldVal;
                }
                // Array type
                if (Array.isArray(this.appliedValues[appliedValuesKey].value)) {
                    return JSON.stringify(this.appliedValues[appliedValuesKey].value) !== JSON.stringify(editableValue);
                }
                // Other values
                return this.appliedValues[appliedValuesKey].value !== editableValue;
            };

            if (shouldUpdateDom()) {
                this.updateLiveDom(editable, editableValue, settings);
            }
        },

        watchGroupValues() {
            this.updateDomTemplateEditables();
        },

        watchLanguage() {
            this.appliedValues[this.selectedLanguage] = {};
            // lets reapply all the dom changes on language change
            this.updateDomTemplateEditables();

            // Run update content
            const update = {
                name: "language",
                type: "metadata",
                value: this.selectedLanguage
            };
            this.triggerBannerUpdate(update);
        },

        watchSavedOverwrites() {
            this.livePreviewValues = this.previewValues;

            if (this.removedAsset) {
                this.watchGroupValues();
                this.removedAsset = null;
            }
        },

        watchRemovedOverwrites(currentRemovedOverwriteIds, previousRemovedOverwriteIds) {
            const removedOverwriteIds = [...new Set(currentRemovedOverwriteIds.concat(previousRemovedOverwriteIds))];
            const affectedOverwrites = this.$store.state.campaign.normalized.overwrites.filter(overwrite =>
                removedOverwriteIds.includes(overwrite._id)
            );

            affectedOverwrites.forEach(overwrite => {
                const editable = this.normalizedTemplateEditables[overwrite.editableId];
                const groupsAffectingEditable = editable.defaultValues[0].editableGroups;
                this.updateEditableDomValue(editable, groupsAffectingEditable);
            });
        },

        watchOverwrites(current, previousEditablesWithLocalOverwrites) {
            // Apply current overwrites to the banner
            const editableIdsWithLocalOverwrites = this.editablesWithLocalOverwrites.map(editable => {
                const groupsAffectingEditable = editable.defaultValues[0].editableGroups;
                this.updateEditableDomValue(editable, groupsAffectingEditable);

                return editable._id;
            });

            // If overwrites were removed then we need to reapply the original values
            previousEditablesWithLocalOverwrites.forEach(editableOverwrite => {
                // These ones have already been applied in the loop above
                if (editableIdsWithLocalOverwrites.includes(editableOverwrite.editable._id)) {
                    return;
                }

                const groups = this.getAffectingGroups(editableOverwrite.editable, this.masterTemplate._id);
                const relevantGVIds = groups.reduce((acc, curr) => {
                    return acc.concat(this.$store.state.campaign.normalized.editableGroups[curr].editableGroupValues);
                }, []);

                const relevantOverwrites = editableOverwrite.overwrites.filter(overwrite =>
                    overwrite.editableGroupValueIds.some(egvId => relevantGVIds.includes(egvId))
                );
                if (relevantOverwrites.length) {
                    this.updateEditableDomValue(editableOverwrite.editable, groups);
                }
            });
        }
    }
};
</script>
<style lang="scss">
dt {
    font-weight: bold;
    padding-left: 10px;
}

dd {
    margin-left: 30px;
}

.livebanner-wrapper--resizable {
    width: 100%;
    height: 100%;
}

.banner-preview {
    position: relative;
    justify-content: center;
}
</style>
