<template>
    <div class="meta-if-post">
        <div class="meta-if-post__avatar">Instagram</div>
        <div class="meta-if-post__header">
            <div class="meta-if-post__user-info">
                <div class="meta-if-post__user-name">
                    Business Name
                    <br />
                    <span class="meta-if-post__sponsored">Sponsored</span>
                </div>
            </div>
            <div class="meta-if-post__options">⋯</div>
        </div>
        <div class="meta-if-post__content">
            <div class="post_media single">
                <slot></slot>
            </div>
            <div class="meta-if-post__description">
                <button class="meta-if-post__description-btn" :v-if="metaOverwrites?.fbCallToAction">
                    {{ metaOverwrites.fbCallToAction }}
                    <span>&#10095;</span>
                </button>
            </div>
        </div>
        <div class="meta-if-post__footer">
            <div class="meta-if-post__divider"></div>
            <div class="meta-if-post__actions">
                <Icon type="ios-heart-outline" />
                <Icon type="ios-chatbubbles-outline" />
                <Icon type="ios-send-outline" />
                <Icon type="ios-bookmark-outline" />
            </div>
            <div class="meta-if-post__text" :v-if="metaOverwrites?.fbPrimaryText">
                {{ metaOverwrites?.fbPrimaryText }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InstagramFeed",
    props: {
        metaOverwrites: {
            type: Object,
            default: () => ({})
        }
    }
};
</script>

<style lang="scss">
.meta-if-post {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ccd0d5;
    border-bottom: 1px solid #ccd0d5;
    margin-bottom: 20px;

    .meta-if-post__avatar {
        width: 100%;
        text-align: center;
        margin-right: 10px;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        font-weight: bold;
        background: #f5f5f5;
        i {
            width: 100%;
            height: 100%;
            font-size: 42px;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        padding: 12px 16px;

        .meta-if-post__user-info {
            flex-grow: 1;

            .meta-if-post__user-name {
                font-weight: bold;
                font-size: 14px;
            }

            .meta-if-post__sponsored {
                font-weight: normal;
                font-size: 12px;
                color: #606770;
            }

            .meta-if-post__time {
                color: #606770;
                font-size: 12px;
            }
        }

        .meta-if-post__options {
            font-size: 24px;
            color: #606770;
            cursor: pointer;
        }
    }

    &__content {
        padding: 16px 0 0;

        .meta-if-post__image {
            width: 100%;
            border-radius: 8px;
        }
    }
    &__description {
        display: flex;
        padding: 0 16px;
        &-btn {
            flex-grow: unset;
            margin: auto 0 auto auto;
            display: flex;
            border-radius: 4px;
            border: 0;
            padding: 5px 0;
            width: 100%;
            background: none;
            font-weight: 600;
            font-size: 12px;
            color: #000;
            text-align: left;
            span {
                display: inline-block;
                margin-left: auto;
            }
        }
    }
    &__text {
        font-size: 12px;
        padding-top: 10px;
    }
    &__footer {
        padding: 0 16px 50px 16px;

        .meta-if-post__divider {
            height: 1px;
            background-color: #dddfe2;
            margin: 5px 0;
        }

        .meta-if-post__actions {
            display: flex;
            gap: 10px;
            justify-content: space-around;
            color: #606770;
            font-size: 14px;
            i {
                font-size: 29px;
                color: #000;
                font-weight: 600;
                &:last-child {
                    margin-left: auto;
                }
            }
        }
    }
}
</style>
