<template>
    <div class="meta-fbr" :class="wrapperExtraClass">
        <div class="meta-fbr__content">
            <div class="post_media single">
                <slot></slot>
            </div>
            <div class="meta-fbr__action-buttons">
                <Icon type="ios-heart-outline" />
                <Icon type="ios-chatbubbles-outline" />
                <Icon type="ios-send-outline" />
                <Icon type="ios-more" />
            </div>
            <div class="meta-fbr__info">
                <div class="meta-fbr__info-title">
                    <Icon type="logo-instagram" />
                    <span>Business Name</span>
                </div>
                <div class="meta-fbr__info-text" :v-if="metaOverwrites?.fbPrimaryText">
                    {{ metaOverwrites?.fbPrimaryText }}
                    <span>...more</span>
                </div>
                <button class="meta-fbr__info-btn" :v-if="metaOverwrites?.fbCallToAction">
                    {{ metaOverwrites.fbCallToAction }}
                </button>
            </div>
        </div>
        <div class="meta-fbr__footer">Sponsored</div>
        <div class="meta-fbr__footer-overlay"></div>
    </div>
</template>

<script>
import { isNineBySixteen } from "@/utils/activate";

export default {
    name: "FacebookReel",
    props: {
        metaOverwrites: {
            type: Object,
            default: () => ({})
        },

        masterTemplate: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        wrapperExtraClass() {
            if (this.isNineBySixteen(this.masterTemplate)) {
                return " full-height";
            }
            return "";
        }
    },
    methods: {
        isNineBySixteen
    }
};
</script>

<style lang="scss">
.meta-fbr {
    width: 100%;
    background: #000;
    margin-bottom: 20px;
    position: relative;

    &__header {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: 40px 10px 10px;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        i {
            margin-left: auto;
            font-size: 32px;
        }
    }

    &__content {
        background: #000;
        padding: 100px 0;
    }

    &__action-buttons {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 2;
        right: 10px;
        bottom: 20px;
        gap: 10px;
        i {
            font-size: 34px;
            font-weight: bold;
            color: #fff;
        }
    }

    &__info {
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 2;
        bottom: 50px;
        left: 16px;
        width: 70%;
        color: #fff;
        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: bold;
            position: relative;
            i {
                font-size: 34px;
                margin-left: -3px;
            }
        }
        &-btn {
            display: flex;
            position: relative;
            padding: 5px 10px;
            border: none;
            border-radius: 4px;
            margin: 5px 0;
            width: 100%;
            font-size: 12px;
            text-align: center;
            color: #000;
            background: #fff;
        }
        &-text {
            display: flex;
            color: #fff;
            span {
                display: inline-block;
                margin-left: auto;
            }
        }
    }

    &__footer {
        padding: 0 16px 12px 16px;
        position: absolute;
        display: flex;
        gap: 10px;
        bottom: 10px;
        left: 0;
        z-index: 2;
        color: #fff;
        font-size: 12px;

        &-overlay {
            position: absolute;
            bottom: 0;
            z-index: 0;
            width: 100%;
            height: 400px;
            background: rgb(87, 87, 87);
            background: linear-gradient(0deg, rgba(87, 87, 87, 1) 0%, rgba(255, 255, 255, 0) 55%);
        }
    }

    &.full-height {
        .meta-fbr__content {
            padding: 0;
        }
    }
}
</style>
