<template>
    <div class="meta-isv" :class="wrapperExtraClass">
        <div class="meta-isv__header">
            <div class="meta-isv__avatar"></div>
            <div class="meta-isv__user-info">
                <span class="meta-isv__user-name"></span>
                <span class="meta-isv__sponsored"></span>
            </div>
            <div class="meta-isv__options">⋯</div>
        </div>
        <div class="meta-isv__content">
            <div v-if="adSubType === AdSubType.Video" class="meta-isv__video">
                <div class="post_media single">
                    <slot></slot>
                    <div v-if="showVideoLayer" class="meta-isv__video-overlay">
                        <div class="meta-isv__video-count">
                            <span>0:0{{ adCounter }}</span>
                            - Sponsored
                        </div>
                        <button>{{ metaOverwrites.fbCallToAction }}</button>
                        <div class="meta-isv__video-progress-bar">
                            <div
                                class="meta-isv__video-progress-bar__fill"
                                :class="{ animated: isProgressBarAnimated }"
                            ></div>
                        </div>
                    </div>
                    <div v-else class="meta-isv__video-overlay play">
                        <div class="meta-isv__video-play">
                            <span @click="startVideoPlay">
                                <Icon type="md-play" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="meta-isv__description">
                    <div class="meta-isv__description-info">
                        <div class="meta-isv__description-avatar">
                            <Icon type="logo-facebook" />
                        </div>
                        <div v-if="adSize === 500" class="meta-isv__description-text">
                            <div class="meta-isv__text">Company Name</div>
                            <p v-if="metaOverwrites.fbHeadline">
                                {{ metaOverwrites.fbHeadline }}
                            </p>
                            <a v-if="metaOverwrites?.fbDisplayLink" class="meta-isv__description-link" :href="safeUrl">
                                {{ metaOverwrites.fbDisplayLink }}
                            </a>
                        </div>
                        <div v-else class="meta-isv__description-text">
                            <a v-if="metaOverwrites?.fbDisplayLink" class="meta-isv__description-link" :href="safeUrl">
                                {{ metaOverwrites.fbDisplayLink }}
                            </a>
                            <p v-if="metaOverwrites.fbHeadline">
                                <strong>{{ metaOverwrites.fbHeadline }}</strong>
                            </p>
                            <div v-if="metaOverwrites?.fbPrimaryText" class="meta-isv__text">
                                {{ metaOverwrites?.fbPrimaryText }}
                            </div>
                        </div>
                    </div>
                    <Icon type="ios-more" />
                </div>
            </div>
            <div v-if="adSubType === AdSubType.Banner" class="meta-isv__banner">
                <div class="meta-isv__banner-play">
                    <span>
                        <Icon type="md-play" />
                    </span>
                </div>
                <div class="meta-isv__banner-header">
                    <div class="meta-isv__banner-header-avatar">
                        <Icon type="logo-facebook" />
                    </div>
                    <div class="meta-isv__banner-header-user-info">
                        <div class="meta-isv__banner-header-user-name">
                            Business Name
                            <br />
                            <span class="meta-isv__banner-header-sponsored">Sponsored</span>
                        </div>
                    </div>
                    <div class="meta-isv__banner-header-options">
                        <Icon type="ios-more" />
                        <Icon type="ios-close" />
                    </div>
                </div>
                <div class="meta-isv__banner-content">
                    <div class="meta-isv__banner-content-video">
                        <slot></slot>
                    </div>
                    <div class="meta-isv__banner-content-info">
                        <span v-if="metaOverwrites.fbHeadline">{{ metaOverwrites?.fbHeadline }}</span>
                        <span v-if="metaOverwrites.fbPrimaryText">{{ metaOverwrites?.fbPrimaryText }}</span>
                        <a v-if="metaOverwrites.fbDisplayLink && adSize === 500">{{ metaOverwrites?.fbDisplayLink }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="meta-isv__footer">
            <div class="meta-isv__footer-blocks"></div>
            <div class="meta-isv__divider"></div>
            <div class="meta-isv__actions">
                <div class="meta-isv__action">
                    <span>
                        <Icon type="ios-thumbs-up-outline" />
                        Like
                    </span>
                </div>
                <div class="meta-isv__action">
                    <span>
                        <Icon type="ios-chatbubbles-outline" />
                        Comment
                    </span>
                </div>
                <div class="meta-isv__action">
                    <span>
                        <Icon type="ios-share-alt-outline" />
                        Share
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AdSubType } from "@/enums/assemble";
import { sanitizeUrl } from "@/utils/activate";

export default {
    name: "FacebookInStream",
    props: {
        metaOverwrites: {
            type: Object,
            default: () => ({})
        },

        adSubType: {
            type: String,
            default: AdSubType.Video
        },

        adSize: {
            type: Number,
            default: 300
        }
    },
    data() {
        return {
            AdSubType,
            safeUrl: "",
            isProgressBarAnimated: false,
            showVideoLayer: false,
            adCounter: 8,
            countInterval: null
        };
    },
    computed: {
        wrapperExtraClass() {
            if (this.adSubType === AdSubType.Video && this.adSize === 500) {
                return "meta-isv__desktop-video";
            }
            if (this.adSubType === AdSubType.Banner && this.adSize === 500) {
                return "meta-isv__desktop-banner";
            }
            return "";
        }
    },
    watch: {
        adSubType() {
            if (this.adSubType === AdSubType.Video) {
                this.startVideoPlay();
            } else {
                this.resetAnimation();
            }
        }
    },
    mounted() {
        if (this.metaOverwrites.fbWebsiteUrl) {
            this.safeUrl = this.sanitizeUrl(this.metaOverwrites.fbWebsiteUrl);
        }
    },
    beforeDestroy() {
        this.resetAnimation();
    },
    methods: {
        startVideoPlay() {
            this.isProgressBarAnimated = true;
            this.showVideoLayer = true;
            this.countInterval = setInterval(() => {
                this.adCounter -= 1;
                if (this.adCounter === 0) {
                    this.resetAnimation();
                }
            }, 1000);
        },
        resetAnimation() {
            clearInterval(this.countInterval);
            this.isProgressBarAnimated = false;
            this.showVideoLayer = false;
            this.adCounter = 8;
        },
        sanitizeUrl
    }
};
</script>

<style lang="scss">
.meta-isv {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ccd0d5;
    border-bottom: 1px solid #ccd0d5;
    margin-bottom: 20px;

    &__header {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        border-bottom: 1px solid #dddfe2;

        .meta-isv__avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            background: #ececec;
        }

        .meta-isv__user-info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 5px;

            span {
                height: 10px;
                background: #ececec;
                display: inline-block;
                border-radius: 5px;
                width: 45%;
            }

            .meta-isv__sponsored {
                width: 30%;
            }

            .meta-isv__time {
                color: #606770;
                font-size: 12px;
            }
        }

        .meta-isv__options {
            font-size: 24px;
            color: #606770;
            cursor: pointer;
        }
    }

    &__content {
        padding: 16px 0;

        .meta-isv__text {
            font-size: 16px;
            padding: 0 16px;
            margin-bottom: 15px;
            color: #1c1e21;
        }

        .meta-isv__image {
            width: 100%;
            border-radius: 8px;
        }
    }
    &__description {
        display: flex;
        align-items: center;
        padding: 20px;
        border-radius: 20px;
        background: #f0f2f5;
        margin: 10px 10px 0;
        &-info {
            display: flex;
            gap: 10px;
        }
        &-avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: #ccc;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            i {
                font-size: 80px;
            }
        }
        &-text {
            padding-right: 0;
            font-weight: 600;
            color: #1c1e21;
            a {
                &.meta-isv__description-link {
                    color: #606770;
                    display: inline-block;
                }
            }
        }
        .meta-isv__text {
            padding: 0;
            font-size: 14px;
            margin-bottom: 0;
        }
        > i {
            margin-left: auto;
            font-size: 24px;
            font-weight: 600;
        }
    }
    &__banner {
        &-play {
            background: #f0f2f5;
            padding: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                height: 75px;
                width: 75px;
                display: flex;
                border-radius: 50%;
                border: 5px solid #fff;
                align-items: center;
                justify-content: center;
                i {
                    font-size: 32px;
                    color: #fff;
                }
            }
        }
        &-header {
            display: flex;
            align-items: center;
            padding: 12px 16px;

            .meta-isv__banner-header-avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 10px;
                i {
                    width: 100%;
                    height: 100%;
                    font-size: 42px;
                }
            }

            .meta-isv__banner-header-user-info {
                flex-grow: 1;

                .meta-isv__banner-header-user-name {
                    font-weight: bold;
                    font-size: 14px;
                }

                .meta-isv__banner-header-sponsored {
                    font-weight: normal;
                    font-size: 12px;
                    color: #606770;
                    i {
                        font-size: 14px;
                    }
                }

                .meta-isv__banner-header-time {
                    color: #606770;
                    font-size: 12px;
                }
            }

            .meta-isv__banner-header-options {
                i {
                    font-size: 30px;
                    color: #606770;
                    font-weight: 700;
                    cursor: pointer;
                }
            }
        }
        &-content {
            display: flex;
            background: #f0f2f5;
            border-radius: 10px;
            margin: 0 10px;
            overflow: hidden;
            &-video {
                width: 40%;
                .bannerWrapper,
                .banner-component-wrapper.banner-preview__banner {
                    width: 100% !important;
                    height: 100px !important;
                    justify-content: flex-start;
                    iframe {
                        width: 100px !important;
                        height: 100px !important;
                    }
                }
            }
            &-info {
                display: flex;
                flex-direction: column;
                font-size: 12px;
                padding-top: 10px;
                span {
                    &:first-child {
                        font-weight: 700;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    &__video {
        &-overlay {
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgb(149, 149, 149);
            background: linear-gradient(
                0deg,
                rgba(149, 149, 149, 1) 0%,
                rgba(255, 255, 255, 0) 25%,
                rgba(255, 255, 255, 0) 75%
            );

            &.play {
                background: #ecedf0;
            }
        }
        &-count {
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            margin: auto auto 10px 10px;
        }
        button {
            margin: auto 10px 10px auto;
            border-radius: 3px;
            color: #fff;
            font-size: 14px;
            border: 1px solid #fff;
            background: rgba(0, 0, 0, 0.5);
            padding: 5px 10px;
        }
        &-progress-bar {
            height: 4px;
            width: 100%;
            background: #acacac;
            position: absolute;
            left: 0;
            bottom: 0;
            &__fill {
                height: 100%;
                background: #f2a916;
                width: 0;
                &.animated {
                    animation: progressAnimation 8s linear infinite;
                }
            }
        }
        &-play {
            height: 100%;
            width: 100%;
            padding: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            span {
                cursor: pointer;
                height: 75px;
                width: 75px;
                display: flex;
                border-radius: 50%;
                border: 3px solid #fff;
                background: rgba(122, 122, 124, 0.8);
                align-items: center;
                justify-content: center;
                i {
                    font-size: 42px;
                    color: #fff;
                }
            }
        }
        .post_media {
            position: relative;
        }
    }
    &__footer {
        padding: 0 16px 12px 16px;
        &-blocks {
            display: flex;
            &:before,
            &:after {
                display: inline-block;
                content: "";
                width: 40%;
                height: 10px;
                background: #ececec;
                border-radius: 5px;
            }
            &:after {
                width: 25%;
                margin-left: auto;
            }
        }
        .meta-isv__divider {
            height: 1px;
            background-color: #dddfe2;
            margin: 16px 0 8px 0;
        }

        .meta-isv__actions {
            display: flex;
            justify-content: space-around;
            color: #212121;
            font-size: 14px;

            .meta-isv__action {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 8px;
                flex-grow: 1;
                justify-content: center;

                &:first-child {
                    padding-left: 0;
                    justify-content: flex-start;
                }

                &:last-child {
                    padding-right: 0;
                    justify-content: flex-end;
                }

                &:hover {
                    background-color: #f0f2f5;
                    border-radius: 4px;
                }

                span {
                    display: flex;
                    align-items: center;
                }

                .meta-isv__action-icon {
                    margin-right: 6px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &__desktop-video {
        .meta-isv__video {
            button {
                color: #000;
                background: #fff;
            }
        }
        .meta-isv__description {
            border-radius: 10px;
            .meta-isv__text {
                font-size: 16px;
            }
            p {
                font-weight: 500;
                color: #66676b;
            }
            a {
                text-transform: uppercase;
            }
        }
    }
    &__desktop-banner {
        .meta-isv__banner-content-video {
            width: auto;
            margin-right: 15px;
        }
        .meta-isv__banner-content-info {
            font-size: 15px;
            span {
                &:first-child {
                    font-weight: 600;
                    font-size: 16px;
                    color: #000;
                }
                font-weight: 500;
                color: #66676b;
            }
            a {
                font-weight: 500;
                color: #66676b;
                text-transform: uppercase;
            }
        }
    }
}

@keyframes progressAnimation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
</style>
