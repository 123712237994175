<template>
    <div class="meta-nf-post" :class="postExtraClass">
        <div class="meta-nf-post__header">
            <div class="meta-nf-post__avatar">
                <Icon type="logo-facebook" />
            </div>
            <div class="meta-nf-post__user-info">
                <div class="meta-nf-post__user-name">
                    Business Name
                    <br />
                    <span class="meta-nf-post__sponsored">
                        Sponsored -
                        <Icon type="md-globe" />
                    </span>
                </div>
            </div>
            <div class="meta-nf-post__options">⋯</div>
        </div>
        <div class="meta-nf-post__content">
            <div v-if="metaOverwrites?.fbPrimaryText && adSubType !== AdSubType.Video" class="meta-nf-post__text">
                {{ metaOverwrites?.fbPrimaryText }}
            </div>
            <div class="post_media single">
                <div :key="replayKey"><slot></slot></div>
                <div v-if="adSubType === AdSubType.Desktop" class="meta-nf-post__overlay" :class="overlayExtraClass">
                    <div class="meta-nf-post__desktop-info">
                        <span class="meta-nf-post__desktop-play"><Icon type="ios-game-controller-b" /></span>
                        <div>
                            <span>{{ metaOverwrites.fbCallToAction }}</span>
                            <a>http://google.com</a>
                        </div>
                    </div>
                    <Icon type="ios-refresh" class="meta-nf-post__desktop-refresh" @click="replayBanner" />
                </div>
            </div>
            <div class="meta-nf-post__description">
                <div class="meta-nf-post__description-text">
                    <a v-if="metaOverwrites?.fbDisplayLink && adSubType !== AdSubType.Video" :href="safeUrl">
                        {{ metaOverwrites.fbDisplayLink }}
                    </a>
                    <p v-if="metaOverwrites.fbDescription || metaOverwrites.fbHeadline">
                        <strong v-if="metaOverwrites.fbHeadline">{{ metaOverwrites.fbHeadline }}</strong>
                        <br v-if="metaOverwrites.fbDescription" />
                        <span v-if="adSubType !== AdSubType.Video">{{ metaOverwrites?.fbDescription }}</span>
                    </p>
                    <div
                        v-if="metaOverwrites?.fbPrimaryText && adSubType === AdSubType.Video"
                        class="meta-nf-post__description-text--primary"
                    >
                        {{ metaOverwrites?.fbPrimaryText }}
                    </div>
                </div>
                <button v-if="metaOverwrites?.fbCallToAction" class="meta-nf-post__description-btn">
                    {{ metaOverwrites.fbCallToAction }}
                </button>
            </div>
        </div>
        <div class="meta-nf-post__footer">
            <div class="meta-nf-post__divider"></div>
            <div class="meta-nf-post__actions">
                <div class="meta-nf-post__action">
                    <span>
                        <Icon type="ios-thumbs-up-outline" />
                        Like
                    </span>
                </div>
                <div class="meta-nf-post__action">
                    <span>
                        <Icon type="ios-chatbubbles-outline" />
                        Comment
                    </span>
                </div>
                <div class="meta-nf-post__action">
                    <span>
                        <Icon type="ios-share-alt-outline" />
                        Share
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AdSubType } from "@/enums/assemble";
import { sanitizeUrl } from "@/utils/activate";

export default {
    name: "FacebookNewsFeed",
    props: {
        metaOverwrites: {
            type: Object,
            default: () => ({})
        },

        adSubType: {
            type: String,
            default: AdSubType.Video
        }
    },

    data() {
        return {
            AdSubType,
            safeUrl: "",
            replayKey: 0,
            overlayExtraClass: "",
            hideTimeout: null
        };
    },
    computed: {
        postExtraClass() {
            switch (this.adSubType) {
                case AdSubType.Video:
                    return "meta-nf-post--video";
                case AdSubType.Tab:
                    return "meta-nf-post--tab";
                case AdSubType.Desktop:
                    return "meta-nf-post--desktop";
                default:
                    return "";
            }
        }
    },
    watch: {
        adSubType(type) {
            if (type === AdSubType.Desktop) {
                clearTimeout(this.hideTimeout);
                this.overlayExtraClass = "hidden";
                this.hideTimeout = setTimeout(() => {
                    this.overlayExtraClass = "";
                }, 10000);
            }
        }
    },
    mounted() {
        if (this.metaOverwrites.fbWebsiteUrl) {
            this.safeUrl = this.sanitizeUrl(this.metaOverwrites.fbWebsiteUrl);
        }
    },
    beforeDestroy() {
        clearTimeout(this.hideTimeout);
    },
    methods: {
        replayBanner() {
            this.replayKey += 1;
            this.overlayExtraClass = "hidden";
            this.hideTimeout = setTimeout(() => {
                this.overlayExtraClass = "";
            }, 10000);
        },
        sanitizeUrl
    }
};
</script>

<style lang="scss">
.meta-nf-post {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ccd0d5;
    border-bottom: 1px solid #ccd0d5;
    margin-bottom: 20px;

    &__header {
        display: flex;
        align-items: center;
        padding: 12px 16px;

        .meta-nf-post__avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            i {
                width: 100%;
                height: 100%;
                font-size: 42px;
            }
        }

        .meta-nf-post__user-info {
            flex-grow: 1;

            .meta-nf-post__user-name {
                font-weight: bold;
                font-size: 14px;
            }

            .meta-nf-post__sponsored {
                font-weight: normal;
                font-size: 12px;
                color: #606770;
                i {
                    font-size: 14px;
                }
            }

            .meta-nf-post__time {
                color: #606770;
                font-size: 12px;
            }
        }

        .meta-nf-post__options {
            font-size: 24px;
            color: #606770;
            cursor: pointer;
        }
    }

    &__content {
        padding: 0 0 10px;

        .meta-nf-post__text {
            font-size: 16px;
            padding: 0 16px;
            margin-bottom: 15px;
            color: #1c1e21;
        }

        .meta-nf-post__image {
            width: 100%;
            border-radius: 8px;
        }
    }
    &__description {
        display: flex;
        padding: 10px 16px;
        background: #f7f8fa;
        border-bottom: 1px solid #e4e5e6;
        color: #000;
        &-text {
            padding-right: 10px;
            a {
                display: inline-block;
                color: #000 !important;
            }
        }
        &-btn {
            background: #e3e5e9;
            color: #000;
            margin: auto 0 auto auto;
            flex: 0 0 auto;
            border-radius: 4px;
            font-weight: 600;
            border: 0;
            padding: 5px 15px;
        }
    }
    &__footer {
        padding: 0 16px 12px 16px;

        .meta-nf-post__divider {
            height: 1px;
            background-color: #dddfe2;
            margin: 8px 0;
        }

        .meta-nf-post__actions {
            display: flex;
            justify-content: space-around;
            color: #606770;
            font-size: 14px;

            .meta-nf-post__action {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 8px;
                flex-grow: 1;
                justify-content: center;

                &:hover {
                    background-color: #f0f2f5;
                    border-radius: 4px;
                }

                span {
                    display: flex;
                    color: #000;
                    align-items: center;
                }

                .meta-nf-post__action-icon {
                    margin-right: 6px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &--video {
        background: #373737;
        color: #fff;
        .meta-nf-post__header {
            border: 0;
        }
        .meta-nf-post__content {
            padding-top: 0;
        }
        .meta-nf-post__description {
            flex-direction: column;
            border-bottom: 0;
        }
        .meta-nf-post__description-btn {
            background: #141415;
            width: 100%;
            color: #fff;
            margin: 10px 0;
        }
        .meta-nf-post__description {
            color: #fff;
            background: #333;
        }
        .meta-nf-post__header {
            .meta-nf-post__options {
                color: #fff;
            }
        }
        .meta-nf-post__header {
            .meta-nf-post__user-info {
                .meta-nf-post__sponsored {
                    color: #fff;
                }
            }
        }
        .meta-nf-post__footer {
            .meta-nf-post__actions {
                span {
                    color: #fff;
                }
                .meta-nf-post__action {
                    color: #fff;
                    padding: 0 8px;
                }
            }
        }
    }
    &--tab {
        .meta-nf-post__description-btn,
        .meta-nf-post__description {
            background: #e9eaee;
        }

        .meta-nf-post__description-btn {
            color: #000;
            border: 1px solid #000;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 12px;
        }
    }

    &--desktop {
        .post_media {
            position: relative;
        }
        .meta-nf-post__content {
            padding-bottom: 0;
        }
        .meta-nf-post__divider {
            background: none;
            margin-top: 0;
        }
        .meta-nf-post__overlay {
            background: rgba(0, 0, 0, 0.75);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            &.hidden {
                display: none;
            }
        }
        .meta-nf-post__desktop {
            &-info {
                display: flex;
                > div {
                    display: flex;
                    flex-direction: column;
                    span {
                        font-weight: 600;
                    }
                    a {
                        color: #fff;
                    }
                }
            }
            &-play {
                padding: 5px;
                border-radius: 50%;
                border: 2px solid #fff;
                margin-right: 10px;
                i {
                    font-size: 26px;
                    rotate: -30deg;
                }
            }
            &-refresh {
                position: absolute;
                bottom: 10px;
                left: 10px;
                font-size: 28px;
                font-weight: 600;
                cursor: pointer;
            }
        }
        .meta-nf-post__description-btn {
            color: #fff;
            background: #0766ff;
        }
    }
}
</style>
