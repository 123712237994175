const AdSubType = {
    Video: "video",
    Banner: "banner",
    Instagram: "instagram",
    Tab: "tab",
    Desktop: "desktop",
    DesktopVideo: "desktop-video",
    MessengerStory: "messenger-story"
};

export { AdSubType };
