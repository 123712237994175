<template>
    <div class="campaign-edit__header dark-scrollbar">
        <section-nav />

        <div class="campaign-edit__header-action-wrapper left">
            <Select v-if="showLanguageSelect" v-model="currentLanguage" class="campaign-edit__header-action-select">
                <Option v-for="lang in campaignLanguages" :key="lang" :value="lang">
                    {{ localeToFriendlyName(lang) }}
                </Option>
            </Select>
            <Button
                v-if="showAddLanguage"
                class="campaign-edit__header-action-btn campaign-edit__header-action-btn--add-language"
                data-testid="editor-header__add-language-button"
                @click="addLanguage"
            >
                Add Language
            </Button>
            <Button
                v-if="showAddTemplates && $auth.userCan($auth.Actions.CanManageMasterTemplates, { clientId }, false)"
                class="campaign-edit__header-action-btn campaign-edit__header-action-btn--add-template"
                data-testid="editor-header__add-template-button"
                @click="addTemplate"
            >
                Add Template
            </Button>
            <Button
                v-if="showAddAssets && $auth.userCan($auth.Actions.CanManageMediaItems)"
                class="campaign-edit__header-action-btn campaign-edit__header-action-btn--add-assets"
                data-testid="editor-header__add-assets-button"
                @click="showAssetsModal"
            >
                Add Assets
            </Button>
            <Button
                v-if="showAddPlatform && clientHasConnectEnabled && !hasMetaPlatform"
                class="campaign-edit__header-action-btn campaign-edit__header-action-btn--add-platform"
                data-testid="editor-header__add-platform-button"
                @click="addPlatform"
            >
                Add Meta Platform
            </Button>
            <Button
                v-if="showImportExport"
                class="campaign-edit__header-action-btn campaign-edit__header-action-btn--export"
                data-testid="editor-header__import-export-button"
                @click="showImportExportModal"
            >
                Import/Export
            </Button>
        </div>
        <div class="campaign-edit__header-action-wrapper right">
            <div class="save-changes">
                <Button
                    class="campaign-edit__header-action-btn"
                    data-testid="editor-header__cancel-button"
                    :disabled="!localOverwritesMade"
                    @click="openCancelChangesModal"
                >
                    Cancel all
                </Button>
                <Button
                    class="campaign-edit__header-action-btn campaign-edit__header-action-btn--primary"
                    type="primary"
                    data-testid="editor-header__create-button"
                    :disabled="!localOverwritesMade"
                    @click="openSaveChangesModal"
                >
                    Save all
                </Button>
            </div>
        </div>
        <alert-modal
            v-model="cancelChangesModal"
            header="Cancel changes"
            title="Are you sure?"
            content="Would you like to discard your changes?"
            ok-text="Discard"
            :on-ok="cancelChanges"
        />
        <alert-modal
            v-model="saveChangesModal"
            header="Save changes"
            title="Are you sure?"
            content="Your content edits will be applied to the ads in this campaign. This cannot be undone. <br>Once saved, you can preview your ads in the Creative Library."
            ok-text="Save"
            :warning="true"
            :on-ok="saveChanges"
        />
    </div>
</template>

<script>
import AlertModal from "@/components/Modal/Alert";
import langUtils from "@/utils/languages";
import SectionNav from "@/components/SectionNav";
import { EditorAction } from "@/store/modules/editor";
import { MediaLibraryAction } from "@/store/modules/mediaLibrary";
import { campaignDefaultLanguage } from "@/store/modules/campaign";
import attachPlatformEditablesToCampaign from "@/apollo/mutations/v2/AttachPlatformEditablesToCampaign.gql";

export default {
    name: "EditorHeader",
    components: { AlertModal, SectionNav },

    props: {
        showLanguageSelect: {
            type: Boolean,
            default: true
        },
        showAddLanguage: {
            type: Boolean,
            default: true
        },
        showAddTemplates: {
            type: Boolean,
            default: true
        },
        showAddAssets: {
            type: Boolean,
            default: true
        },
        showImportExport: {
            type: Boolean,
            default: true
        },
        hasMetaPlatform: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            cancelChangesModal: false,
            saveChangesModal: false,
            showAddPlatform: true
        };
    },

    computed: {
        campaignLanguages() {
            return this.$store.state.campaign.languages;
        },
        clientId() {
            return this.$store.state.route.params.clientId;
        },
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },
        localOverwritesMade() {
            return (
                this.$store.state.editor.localEditableOverwrites.length > 0 ||
                this.$store.state.editor.removedOverwriteIds.length > 0
            );
        },
        currentLanguage: {
            get() {
                return this.$store.state.editor.selectedLanguage;
            },
            set(val) {
                this.$store.dispatch(EditorAction.SelectLanguage, val);
            }
        },
        clientHasConnectEnabled() {
            if (this.$store.state.ui.currentClient) {
                return this.$store.state.ui.currentClient.connectModuleEnabled;
            }

            return false;
        }
    },

    watch: {
        adType(val) {
            this.$emit("changeAdType", val);
        }
    },

    created() {
        if (this.currentLanguage !== campaignDefaultLanguage) {
            this.currentLanguage = campaignDefaultLanguage;
        }
    },

    methods: {
        addLanguage() {
            this.$emit("addLanguage");
        },

        addTemplate() {
            this.$emit("addTemplate");
        },

        cancelChanges() {
            this.$store.dispatch(EditorAction.ClearLocalChanges);
        },

        openCancelChangesModal() {
            this.cancelChangesModal = true;
        },

        openSaveChangesModal() {
            this.saveChangesModal = true;
        },

        saveChanges() {
            this.$emit("saveChanges");
        },

        localeToFriendlyName(locale) {
            return langUtils.localeToFriendlyName(locale);
        },

        showAssetsModal() {
            this.$store.dispatch(MediaLibraryAction.Open, {});
        },

        showImportExportModal() {
            this.$emit("showImportExportModal");
        },

        async addPlatform() {
            try {
                await this.$apollo.mutate({
                    mutation: attachPlatformEditablesToCampaign,
                    variables: {
                        campaignId: this.campaignId,
                        // hardcoded temporarly until we add another platform
                        publishingPlatform: "facebook"
                    }
                });
                this.$snackbar.success("Platform added");
                this.$emit("publishToPlatform");
                const addPlatformButton = this.$el.querySelector(".campaign-edit__header-action-btn--add-platform");
                if (addPlatformButton) {
                    addPlatformButton.blur();
                }
            } catch (err) {
                this.$snackbar.error(err.message);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.campaign-edit__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: -20px 0 0 0;
    height: 60px;

    &-action-wrapper {
        flex: 1;
        display: flex;

        &.right {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            white-space: nowrap;
        }
    }

    &-action-select {
        max-width: 175px;
    }

    &-action-btn,
    &-action-select .ivu-select-selection,
    &-action-select .ivu-select-dropdown {
        background: $cmp-dark-btn-bg-color;
        color: $cmp-dark-font-color;
        border: 1px solid $cmp-dark-btn-bg-color;
        margin-right: 5px;

        > span {
            display: block;
        }

        &[disabled] {
            opacity: 0.5;
            background: $cmp-dark-btn-bg-color;
            color: $cmp-dark-font-color;
            border: 1px solid $cmp-dark-btn-bg-color;

            &:hover {
                background: $cmp-dark-btn-bg-color;
                color: $cmp-dark-font-color;
                border: 1px solid $cmp-dark-btn-bg-color;
            }
        }

        &:hover {
            background: lighten($cmp-dark-btn-bg-color, 10);
            border: 1px solid lighten($cmp-dark-btn-bg-color, 10);
            color: $cmp-dark-font-color;
        }

        &--primary {
            background: $blue;
            border: 1px solid $blue;

            &[disabled] {
                background: $blue;
                border: 1px solid $blue;

                &:hover {
                    background: $blue;
                    border: 1px solid $blue;
                }
            }

            &:hover {
                background: lighten($blue, 10);
                border: 1px solid lighten($blue, 10);
            }
        }
    }

    &-action-select.ivu-select-single .ivu-select-selection .ivu-select-selected-value {
        padding-left: 15px;
        padding-right: 32px;
    }

    &-action-select .ivu-select-dropdown {
        margin-top: 1px;
        padding: 0;
        height: auto;

        &:hover {
            background: $cmp-dark-btn-bg-color;
        }

        .ivu-select-item {
            color: $cmp-dark-font-color;
            border-bottom: 1px solid $cmp-dark-border-color;
            padding: 10px;

            &:last-child {
                border-bottom: none;
            }

            &.ivu-select-item-selected {
                color: $cmp-dark-active;
            }

            &.ivu-select-item-focus {
                background: $cmp-dark-btn-bg-color;
            }

            &:hover {
                background: lighten($cmp-dark-btn-bg-color, 10);
            }
        }
    }
}
</style>
