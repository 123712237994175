export const parseTemperatureValueString = inputStr => {
    let str = inputStr;
    if (str.startsWith("X")) {
        str = str.substring(1);
    }
    const regex = /^(>=|<=|>|<|===)?(\d+)$/;
    const match = str.match(regex);

    if (match) {
        return {
            operator: match[1] || "",
            value: parseInt(match[2], 10)
        };
    }
    return {
        operator: "",
        value: null
    };
};
export const sanitizeUrl = url => {
    const allowedProtocols = ["http:", "https:"];
    try {
        const parsedUrl = new URL(url);
        if (allowedProtocols.includes(parsedUrl.protocol)) {
            return url;
        }
    } catch (e) {
        console.warn("Invalid URL", url);
    }
    return "about:blank";
};
export const isNineBySixteen = template => {
    const { width, height } = template;
    const aspectRatio = width / height;
    const targetRatio = 9 / 16;
    const tolerance = 0.1;
    return Math.abs(aspectRatio - targetRatio) < tolerance;
};
