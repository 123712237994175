<template>
    <div class="meta-ir">
        <div class="meta-ir__overlay"></div>
        <div class="meta-ir__header">
            <span>Reels</span>
            <Icon type="logo-instagram" />
        </div>
        <div class="meta-ir__content">
            <div class="post_media single">
                <slot></slot>
            </div>
            <div class="meta-ir__action-buttons">
                <Icon type="ios-heart-outline" />
                <Icon type="ios-chatbubbles-outline" />
                <Icon type="ios-send-outline" />
                <Icon type="ios-more" />
            </div>
            <div class="meta-ir__info">
                <div class="meta-ir__info-title">
                    <Icon type="logo-instagram" />
                    <span>Business Name</span>
                </div>
                <button class="meta-ir__info-btn" :v-if="metaOverwrites?.fbCallToAction">
                    {{ metaOverwrites.fbCallToAction }}
                    <span>&#10095;</span>
                </button>
                <div class="meta-ir__info-text" :v-if="metaOverwrites?.fbPrimaryText">
                    {{ metaOverwrites?.fbPrimaryText }}
                </div>
            </div>
        </div>
        <div class="meta-ir__footer">
            <span>
                <Icon type="ios-megaphone" />
                Sponsored
            </span>
            <span>
                <Icon type="md-musical-note" />
                Original audio
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "InstagramReel",
    props: {
        metaOverwrites: {
            type: Object,
            default: () => ({})
        }
    }
};
</script>

<style lang="scss">
.meta-ir {
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgb(149, 149, 149);
        background: linear-gradient(
            180deg,
            rgba(149, 149, 149, 1) 0%,
            rgba(255, 255, 255, 0) 25%,
            rgba(255, 255, 255, 0) 75%,
            rgba(149, 149, 149, 1) 100%
        );
    }

    &__content {
        min-height: 500px;
    }

    &__header {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: 20px 10px 10px;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        i {
            margin-left: auto;
            font-size: 32px;
        }
    }

    &__action-buttons {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 2;
        right: 10px;
        bottom: 20px;
        gap: 10px;
        i {
            font-size: 34px;
            font-weight: bold;
            color: #fff;
        }
    }

    &__info {
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 2;
        bottom: 60px;
        left: 16px;
        width: 70%;
        color: #fff;
        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: bold;
            position: relative;
            bottom: -40px;
            animation: disappear-reappear-title 10s linear infinite;
            i {
                font-size: 34px;
                margin-left: -3px;
            }
        }
        &-btn {
            display: flex;
            position: relative;
            opacity: 0;
            bottom: -20px;
            padding: 5px 10px;
            border: none;
            border-radius: 4px;
            margin: 5px 0;
            width: 100%;
            font-size: 12px;
            color: #fff;
            background: #b6b6b6;
            animation: disappear-reappear-button 10s linear infinite;
            span {
                display: inline-block;
                margin-left: auto;
            }
        }
        &-text {
        }
    }

    &__footer {
        padding: 0 16px 12px 16px;
        position: absolute;
        display: flex;
        gap: 10px;
        bottom: 10px;
        left: 0;
        z-index: 2;
        span {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #fff;
            border-radius: 10px;
            padding: 3px 5px;
            background: #878787;
            i {
                font-size: 16px;
                margin-right: 5px;
            }
        }
    }
}
@keyframes disappear-reappear-button {
    0% {
        opacity: 0;
        bottom: -20px;
    }
    30% {
        opacity: 0;
        bottom: -20px;
    }
    40% {
        opacity: 1;
        bottom: 0;

        color: #fff;
        background: #b6b6b6;
    }
    50% {
        opacity: 1;
        bottom: 0;
        color: #2a2a2a;
        background: #fff;
    }

    99% {
        opacity: 1;
        bottom: 0;
        color: #2a2a2a;
        background: #fff;
    }

    100% {
        color: #fff;
        background: #b6b6b6;
        opacity: 0;
        bottom: -20px;
    }
}
@keyframes disappear-reappear-title {
    0% {
        bottom: -40px;
    }
    30% {
        bottom: -40px;
    }
    40% {
        bottom: 0;
    }
    100% {
        bottom: 0;
    }
}
</style>
