<template>
    <div class="campaign-edit__deliverable">
        <resizable-campaign-banner
            :banner-name="templateName"
            :master-template="masterTemplate"
            :max-height="maxHeight"
            :max-width="computedWidth"
            :scaling-factor="scalingFactor"
            :sandbox-mode="sandboxMode"
            :show-social-preview-options="isMetaPreview"
            theme="dark"
            @changeAdType="onChangeAdType"
        >
            <template #beforeBannerContainer>
                <div v-if="showPreviewValuesOverlay" class="preview-values-overlay">
                    {{
                        JSON.parse(previewValues).computedOverwrites.map(({ type, newValue, name }) => ({
                            type,
                            name,
                            ...(type === "text" && { newValue })
                        }))
                    }}
                </div>
            </template>

            <template #bannerContainer="{ slotProps }">
                <live-banner-preview
                    v-if="!isMetaPreview"
                    :preview-values="previewValues"
                    :master-template="masterTemplate"
                    :scaling-factor="slotProps.internalScalingFactor"
                    :is-resizable="slotProps.isResizable"
                    @BannerLoaded="onBannerLoaded"
                ></live-banner-preview>
                <component
                    :is="previewComponent"
                    v-else
                    :meta-overwrites="metaOverwrites"
                    :master-template="masterTemplate"
                    :ad-sub-type="adSubType"
                    :ad-size="previewWidth"
                >
                    <live-banner-preview
                        :preview-values="previewValues"
                        :master-template="masterTemplate"
                        :scaling-factor="slotProps.internalScalingFactor"
                        :is-resizable="slotProps.isResizable"
                        @BannerLoaded="onBannerLoaded"
                    />
                </component>
            </template>

            <template #footerControls>
                <campaign-banner-play :banner-id="masterTemplate._id"></campaign-banner-play>
            </template>

            <template #footerOptions="{ slotProps, slotToggleResizeMode }">
                <Poptip
                    v-model="optionsVisible"
                    :transfer="true"
                    popper-class="theme-campaign-dark campaign-edit__poptip--transferred"
                    placement="bottom"
                    class="campaign-edit__poptip hide-title"
                >
                    <icon type="ios-more" />
                    <editor-banner-options
                        slot="content"
                        :is-resizable="slotProps.isResizable"
                        :master-template="masterTemplate"
                        :show-new-size="isHTML5 && !isMetaPreview"
                        :show-resize="isHTML5 && !isMetaPreview"
                        :show-replace="!sandboxMode && !isAE"
                        :show-remove="!sandboxMode"
                        @hide="onOptionsHide"
                        @update="onUpdate(masterTemplate._id)"
                        @updateStarted="onUpdateStarted(masterTemplate._id)"
                        @familyUpdated="onFamilyUpdate"
                        @remove="onRemove(masterTemplate._id)"
                        @resizeMode="slotToggleResizeMode"
                        @newSize="onNewSize"
                    />
                </Poptip>
            </template>
        </resizable-campaign-banner>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import setLayerSettings from "shared-utils/setLayerSettings";
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";
import { CampaignGetters } from "@/store/modules/campaign";
import LiveBannerPreview from "@/components/Campaign/LiveBannerPreview";
import FacebookNewsFeed from "@/components/Campaign/AdPreview/FacebookNewsFeed";
import FacebookStory from "@/components/Campaign/AdPreview/FacebookStory";
import FacebookReel from "@/components/Campaign/AdPreview/FacebookReel";
import InstagramStory from "@/components/Campaign/AdPreview/InstagramStory";
import InstagramReel from "@/components/Campaign/AdPreview/InstagramReel";
import InstagramFeed from "@/components/Campaign/AdPreview/InstagramFeed";
import FacebookInStream from "@/components/Campaign/AdPreview/FacebookInStream";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import CampaignBannerPlay from "@/components/Campaign/CampaignBannerPlay";
import EditorBannerOptions from "@/components/Campaign/EditorBannerOptions";
import { BannerEvents } from "@/enums/banners";
import { deepClone } from "@/utils";
import scalingBannerMixin from "@/mixins/scalingBannerMixin";
import ResizableCampaignBanner from "@/components/Campaign/ResizableCampaignBanner";
import FacebookInStreamFull from "@/components/Campaign/AdPreview/FacebookInStreamFull";

export default {
    name: "DeliverablePreview",
    components: {
        ResizableCampaignBanner,
        EditorBannerOptions,
        CampaignBannerPlay,
        LiveBannerPreview,
        FacebookNewsFeed,
        FacebookStory,
        FacebookReel,
        InstagramFeed,
        InstagramStory,
        FacebookInStream,
        FacebookInStreamFull
    },
    mixins: [editableMethodsMixin, scalingBannerMixin],
    props: {
        masterTemplate: {
            type: Object
        },

        sandboxMode: {
            type: Boolean,
            default: false
        },

        isMetaPreview: {
            type: Boolean,
            default: false
        },

        metaOverwrites: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            optionsVisible: false,
            showPreviewValuesOverlay: false,
            adType: "",
            adSubType: "",
            previewWidth: 300
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        computedWidth() {
            if (this.isMetaPreview) {
                return this.previewWidth;
            }
            return this.maxWidth;
        },

        editables() {
            return this.$store.getters[CampaignGetters.templateEditables](this.masterTemplate._id);
        },

        isPsd() {
            return this.masterTemplate.adType === TemplateType.PSD;
        },

        isAE() {
            return this.masterTemplate.adType === TemplateType.AE;
        },

        isHTML5() {
            return this.masterTemplate.adType === TemplateType.HTML5;
        },

        computedOverwrites() {
            const editables = this.$store.getters[CampaignGetters.templateEditables](this.masterTemplate._id);

            return editables.map(editable => {
                // potential issue this.getEditableValue will take not saved editor changes into account to generate the value
                let layer = null;
                // can use editable groups this way since the results are comming from the store curated
                const groupsAffectingEditable = editable.defaultValues[0].editableGroups;

                const settings = this.getEditableSettings(editable, groupsAffectingEditable, this.masterTemplate._id);
                const newValue = this.getEditablePreviewValue(editable, groupsAffectingEditable, this.masterTemplate);

                if (this.isPsd && editable.layers) {
                    const templateLayer = editable.layers.find(
                        ({ masterTemplateId }) => masterTemplateId === this.masterTemplate._id
                    );

                    if (templateLayer && templateLayer.value) {
                        layer = deepClone(templateLayer.value);
                        setLayerSettings(settings, layer);
                    }
                }

                return {
                    newValue,
                    name: editable.name,
                    type: editable.type,
                    isPublisherPlatform: !!editable.publisherPlatform,
                    layer,
                    htmlTag: "manifest"
                };
            });
        },

        previewValues() {
            return JSON.stringify({
                computedOverwrites: this.computedOverwrites,
                masterTemplate: {
                    _id: this.masterTemplate._id,
                    persistentKey: this.masterTemplate.persistentKey,
                    width: this.masterTemplate.width,
                    height: this.masterTemplate.height,
                    aeInfo: {
                        completed: this.masterTemplate?.aeInfo?.completed,
                        plainlyProjectId: this.masterTemplate?.aeInfo?.plainlyProjectId,
                        plainlyTemplateId: this.masterTemplate?.aeInfo?.plainlyTemplateId
                    },
                    reportingLabel: this.masterTemplate.reportingLabel
                },
                previewUrl: this.masterTemplate.preview,
                campaignId: this.campaignId,
                metadata: [
                    {
                        fieldName: "language",
                        value: this.selectedLanguage
                    }
                ]
            });
        },

        selectedLanguage() {
            return this.$store.state.editor.selectedLanguage;
        },

        templateSize() {
            return {
                width: `${this.masterTemplate.width}px`,
                height: `${this.masterTemplate.height}px`
            };
        },

        templateName() {
            return `${this.masterTemplate.width} x ${this.masterTemplate.height} - ${this.masterTemplate.name}`;
        },

        previewComponent() {
            const componentMap = {
                "news-feed": FacebookNewsFeed,
                "facebook-story": FacebookStory,
                "facebook-reel": FacebookReel,
                "instagram-story": InstagramStory,
                "instagram-feed": InstagramFeed,
                "instagram-reel": InstagramReel,
                "facebook-in-stream": FacebookInStream,
                "facebook-in-stream-full": FacebookInStreamFull
            };

            return componentMap[this.adType] || FacebookNewsFeed;
        },

        previewComponentSubType() {
            return this.adType;
        }
    },

    methods: {
        onBannerLoaded(payload) {
            this.$emit(BannerEvents.BannerLoaded, payload);
        },

        onFamilyUpdate(masterTemplateIds) {
            this.$emit("familyUpdated", masterTemplateIds);
        },

        onNewSize() {
            this.$emit("newSize", this.masterTemplate);
        },

        onOptionsHide() {
            this.optionsVisible = false;
        },

        onRemove(masterTemplateId) {
            this.$emit("remove", masterTemplateId);
        },

        onUpdate(masterTemplateId) {
            this.$emit("update", masterTemplateId);
        },
        onUpdateStarted(masterTemplateId) {
            this.$emit("updateStarted", masterTemplateId);
        },

        onChangeAdType(type) {
            this.adType = type?.adType;
            this.adSubType = type?.adSubType;
            this.previewWidth = Number(type?.adSize);
        }
    }
};
</script>

<style lang="scss">
.preview-values-overlay {
    position: absolute;
    color: green;
    top: 0;
    left: 0;
    z-index: 300;
    background-color: #0d3349;
    opacity: 0.9;
    width: 100%;
    height: 100%;
}

.campaign-edit__poptip {
    &--transferred .ivu-poptip-body {
        padding: 0;
    }

    &-templateName {
        padding: 10px;
    }
}

.campaign-edit__deliverable {
    display: inline-block;
    margin: 0 15px 15px 0;
    vertical-align: top;

    &--resizable {
        display: block;
    }
}
</style>
